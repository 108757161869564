<template>
    <!-- 添加/编辑本地关注人员 -->
    <div class="addEditPersonnel">
        <div class="mask"></div>
        <div class="equipmentCard">
            <!-- 头部 -->
            <div class="cardHeader">
                <div>{{ titlePop }}</div>
                <i class="el-icon-close" @click="cancelEquipmentFun"></i>
            </div>
            <el-form :model="myForm" ref="myForm" :rules="rules">
                <el-form-item label="证件号码" prop="cardNo">
                    <el-input v-model="myForm.cardNo" placeholder="请输入证件号码"></el-input>
                </el-form-item>
                <el-form-item label="证件类型" prop="cardType">
                    <el-select class="addressSty" v-model="myForm.cardType" placeholder="请选择证件类型" filterable>
                        <el-option v-for="(item, index) in natureNames" :key="index" :label="item.text" :value="item.number">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="姓名">
                    <el-input v-model="myForm.name" placeholder="请输入姓名"></el-input>
                </el-form-item>
                <el-form-item label="手机号码">
                    <el-input v-model="myForm.phone" placeholder="请输入手机号码"></el-input>
                </el-form-item>
                <el-form-item label="所属分类" prop="typeNameC">
                    <el-select @change="typeChange" class="addressSty" v-model="typeName" placeholder="请选择所属分类" filterable>
                        <el-option v-for="(item, index) in enterpriseA" :key="index" :label="item.name" :value="item.name">
                        </el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <div class="button">
                <el-button @click="cancelEquipmentFun">取 消</el-button>
                <el-button type="primary" @click="determineEquipmentFun">提 交</el-button>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'addEditPersonnel',
        props: {
            // 标题
            titlePop: {
                type: String,
                default: '添加本地关注人员',
            },
            // 被选择 信息
            companyData: {
                type: Object,
                default() {
                    return {}
                },
            },
        },
        data() {
            return {
                // 0为批量导入 1为单个添加
                radio: '0',
                // 被选中 所属分类name
                typeName: '',
                // 被选中 所属分类id
                typeCode: '',
                // 所属分类 选择
                enterpriseA: [],
                // 证件类型
                natureNames: [
                    {
                        text: '居民身份证',
                        number: '10',
                    },
                    {
                        text: '港澳居民来往内地通行证',
                        number: '14',
                    },
                    {
                        text: '台湾居民来往大陆通行证',
                        number: '15',
                    },
                    {
                        text: '一次性台湾居民来往大陆通行证',
                        number: '16',
                    },
                    {
                        text: '港澳居住证',
                        number: '17',
                    },
                    {
                        text: '台湾居住证',
                        number: '18',
                    },
                    {
                        text: '护照',
                        number: '20',
                    },
                    {
                        text: '外国人永久居留证',
                        number: '34',
                    },
                    {
                        text: '其他',
                        number: '99',
                    },
                ],
                // 表单
                myForm: {},
                rules: {
                    cardNo: [
                        {
                            required: true,
                            message: '请输入证件号码',
                            trigger: 'blur',
                        },
                    ],
                    cardType: [
                        {
                            required: true,
                            message: '请选择证件类型',
                            trigger: 'change',
                        },
                    ],
                    typeNameC: [
                        {
                            required: true,
                            message: '请选择所属分类',
                            trigger: 'blur',
                        },
                    ],
                },
            }
        },
        created() {
            // 所属分类
            this.classifyGet()
            const myForm = {
                ...this.companyData,
            }
            this.typeName = myForm.typeName
            this.typeCode = myForm.typeCode
            this.myForm = myForm
        },
        methods: {
            // 所属分类-选中值发生变化时触发
            typeChange() {
                this.enterpriseA.map(val => {
                    if (val.name === this.typeName) {
                        this.typeCode = val.id
                    }
                })
            },
            // 所属分类 下拉数据
            classifyGet() {
                this.$instance
                    .get('/v1/follow_class/list', {
                        params: {
                            pageSize: 100,
                        },
                    })
                    .then(res => {
                        if (res.data.status === 200) {
                            this.enterpriseA = res.data.data.elements
                        }
                    })
                    .catch(error => {
                        console.log('error: ', error)
                    })
            },
            // 点击取消
            cancelEquipmentFun() {
                this.$emit('cancelEquipment-fun')
            },
            // 点击提交
            determineEquipmentFun() {
                if (
                    !this.myForm.cardNo ||
                    !this.myForm.cardType ||
                    !this.typeName
                ) {
                    this.$message({
                        message: '必填项不能为空！',
                        type: 'error',
                    })
                    return false
                }
                this.enterpriseA.map(val => {
                    if (val.id === this.typeCode) {
                        this.typeName = val.name
                    }
                })

                let value = {
                    // 证件号码
                    cardNo: this.myForm.cardNo,
                    // 证件类型
                    cardType: this.myForm.cardType,
                    name: this.myForm.name,
                    phone: this.myForm.phone,
                    // 所属分类
                    typeCode: this.typeCode,
                    typeName: this.typeName,
                }
                let url = '/v1/follow/add'
                let url2 = '/v1/follow/edit'

                // this.radio 0为批量导入 1为单个添加
                if (this.titlePop === '添加本地关注人员') {
                    this.gatePost(url, value)
                } else if (this.titlePop === '编辑本地关注人员') {
                    this.gatePost(url2, { ...value, id: this.myForm.id })
                }
            },
            // 导入设备
            gatePost(url, value) {
                this.$instance
                    .post(url, value)
                    .then(res => {
                        if (res.data.status === 200) {
                            this.$emit('determineEquipment-fun')
                            this.$message({
                                message: this.titlePop + '成功！',
                                type: 'success',
                            })
                        }
                    })
                    .catch(error => {
                        console.log(error)
                    })
            },
        },
    }
</script>
<style lang="scss" scoped>
    .addEditPersonnel {
        width: 100%;
        height: 100%;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 100;
        .mask {
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.4);
        }
        // 弹窗卡片
        & ::v-deep .equipmentCard {
            width: 520px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            background-color: #fff;
            border-radius: 6px;
            // 头部
            .cardHeader {
                height: 60px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                font-size: 18px;
                padding: 0 40px;
                box-sizing: border-box;
                border-radius: 6px 6px 0 0;
                // 头部-icon
                .el-icon-close {
                    font-size: 24px;
                }
            }
            .el-form {
                padding: 30px 40px 10px;
                border-top: 1px #f0f0f0 solid;
                border-bottom: 1px #f0f0f0 solid;
                .el-form-item {
                    margin-bottom: 20px;
                    .el-form-item__label {
                        width: 90px !important;
                        padding-right: 20px;
                        line-height: 32px;
                    }
                    .el-input__inner {
                        line-height: 32px !important;
                    }
                    .el-form-item__content {
                        margin-left: 90px !important;
                        line-height: 32px;
                        .equipSNSty {
                            margin-bottom: 14px;
                        }
                        .el-button {
                            width: 100px;
                        }
                        .el-select {
                            width: 100%;
                        }
                        input {
                            height: 32px;
                        }
                        i {
                            line-height: 32px;
                        }
                    }
                }
            }
        }
        .button {
            padding: 20px 40px;
            display: flex;
            justify-content: flex-end;
            .el-button {
                line-height: 30px;
                padding: 0 20px;
            }
        }
    }
</style>
