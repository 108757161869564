<template>
    <!-- 本地关注人员管理 -->
    <div class="personnel-admin">
        <div class="companySearch">
            <div>
                <el-button @click="addFun" class="titleButton" type="primary" icon="el-icon-plus">添加本地关注人员</el-button>
                <el-button @click="dialogVisible = true" class="titleButton">批量导入
                </el-button>
                <el-dialog title="批量导入" :visible.sync="dialogVisible" width="30%" :before-close="handleClose">
                    <div class="importCon">
                        <div>为了保证导入成功，请按照下面模版的格式填写信息。</div>
                        <div>
                            <a class="importBu" @click="downloadFun">模版.xlsx</a>
                        </div>
                    </div>
                    <span slot="footer" class="dialog-footer">
                        <!-- <el-button @click="dialogVisible = false" size="small">取 消</el-button> -->
                        <el-upload ref="uploader" action="" :multiple="true" :show-file-list="false" :http-request="httpRequestUpload">
                            <el-button @click="dialogVisible = false" class="titleButton" type="primary">
                                导入
                            </el-button>
                        </el-upload>
                    </span>
                </el-dialog>
            </div>
            <div class="titleSearch">
                <el-select v-model="region" placeholder="所有分类" clearable>
                    <el-option v-for="(item, index) in regions" :key="index" :label="item.name" :value="item.id">
                    </el-option>
                </el-select>
                <el-select v-model="nature" placeholder="证件类型" clearable>
                    <el-option v-for="(item, index) in natures" :key="index" :label="item.text" :value="item.number">
                    </el-option>
                </el-select>
                <el-input @keyup.enter.native="searchClick" clearable v-model="input" placeholder="请输入名称/电话/证件号码"></el-input>
                <el-button @click="searchClick" class="titleButton" type="primary" icon="el-icon-search">搜索</el-button>
                <el-button @click="exportClick" class="titleButton" icon="el-icon-download" :loading="exportLoad">导出数据
                    <a title="data"></a>
                </el-button>
                <el-button @click="determineDelFun" class="titleButton">批量删除
                </el-button>
            </div>
        </div>
        <div class="companyTableC">
            <div class="companyTable">
                <el-table v-loading="loading" :data="pagingData.elements" style="width: 100%" height="100%" @selection-change="handleSelectionChange">
                    <el-table-column type="selection" width="50">
                    </el-table-column>
                    <el-table-column prop="cardNo" label="证件号码" min-width="180">
                    </el-table-column>
                    <el-table-column prop="cardTypeC" label="证件类型" min-width="220">
                    </el-table-column>
                    <el-table-column prop="name" label="姓名" min-width="100">
                    </el-table-column>
                    <el-table-column prop="phone" label="手机号码" min-width="120">
                    </el-table-column>
                    <el-table-column prop="typeName" label="所属分类" min-width="140">
                    </el-table-column>
                    <el-table-column prop="createdAt" label="添加时间" min-width="160" align="center">
                    </el-table-column>
                    <el-table-column label="操作" width="120">
                        <template slot-scope="scope">
                            <el-button @click="editFun(scope.row, scope.$index)" type="text">编辑</el-button>
                            <el-button @click="deleteFun(scope.row, scope.$index)" type="text">删除</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <!-- 分页 -->
            <el-pagination :current-page="currentPage" :page-size="pagingData.pageSize" @current-change="handleCurrentChange" background layout="total, prev, pager, next" :total="pagingData.totalRecords">
            </el-pagination>
        </div>
        <!-- 弹窗-添加/编辑人员 -->
        <addEditPersonnel v-if="addCompanyShow" @cancelEquipment-fun="cancelEquipmentFun" @determineEquipment-fun="determineEquipmentFun" :companyData="
                titlePop === '编辑本地关注人员' ? pagingData.elements[deleteIndex] : {}
            " :titlePop="titlePop"></addEditPersonnel>
        <!-- 弹窗-删除 -->
        <deletePop v-if="deletePopShow" @deletePop-fun="deletePopFun" @determineDel-fun="determineDelFun" :companyName="companyName" :name="name" :catdTitle="catdTitle"></deletePop>
    </div>
</template>
<script>
    import addEditPersonnel from '../../components/addEditPersonnel'
    import deletePop from '../../components/deletePop'

    export default {
        name: 'personnel-admin',
        components: {
            addEditPersonnel,
            deletePop,
        },
        data() {
            return {
                // 是否显示批量导入 对话框
                dialogVisible: false,
                loading: false,
                // 导出 loading
                exportLoad: false,
                // 权限判断
                isDis: true,
                titlePop: '编辑本地关注人员',
                // 是否显示-弹窗-编辑
                addCompanyShow: false,
                // 是否显示-弹窗-删除
                deletePopShow: false,
                // 所有分类
                region: null,
                regions: [],
                // 证件类型
                nature: '',
                natures: [
                    {
                        text: '居民身份证',
                        number: '10',
                    },
                    {
                        text: '港澳居民来往内地通行证',
                        number: '14',
                    },
                    {
                        text: '台湾居民来往大陆通行证',
                        number: '15',
                    },
                    {
                        text: '一次性台湾居民来往大陆通行证',
                        number: '16',
                    },
                    {
                        text: '港澳居住证',
                        number: '17',
                    },
                    {
                        text: '台湾居住证',
                        number: '18',
                    },
                    {
                        text: '护照',
                        number: '20',
                    },
                    {
                        text: '外国人永久居留证',
                        number: '34',
                    },
                    {
                        text: '其他',
                        number: '99',
                    },
                ],
                input: '',
                // 被选中人员名称
                companyName: '本地关注人员',
                name: '的信息',
                catdTitle: '删除本地关注人员',
                // 被选中人员下标
                deleteIndex: null,
                // 人员表格数据
                pagingData: {},
                // 分页-总条数
                totalNum: null,
                // 当前页数
                currentPage: 1,
                //列表-开始位置
                start: 1,
                // 选中的表格
                multipleSelection: [],
                // 上传的文件
                myFile: null,
            }
        },
        created() {
            // 不同权限 被访问到的页面
            let userInfo = sessionStorage.getItem('userInfo') + ''

            // console.log('userInfo: ', userInfo)
            if (userInfo === '3' || userInfo === '4') {
                this.isDis = false
            }
            // 人员列表
            this.comtanyList()
            // 所属分类 下拉数据
            this.classifyGet()
        },
        methods: {
            // 点击 下载模版
            downloadFun() {
                let href = location.origin + '/关注人员导入模板.xlsx'
                const a = document.createElement('a')
                const event = new MouseEvent('click')
                // 下载图名字
                a.download = '关注人员导入模板.xlsx'
                // url
                a.href = href
                // 合成函数，执行下载
                a.dispatchEvent(event)
            },
            // 批量导入？ 关闭icon
            handleClose(done) {
                done()
            },
            // 当表格选择项 发生变化时会触发该事件
            handleSelectionChange(val) {
                this.multipleSelection = val
            },
            // 批量导入 覆盖默认的上传行为，可以自定义上传的实现
            httpRequestUpload(file) {
                this.myFile = file.file
                let form = new FormData()
                form.append('userFile', this.myFile)
                this.$instance
                    .post('/v1/follow/import-user', form)
                    .then(res => {
                        if (res.data.status === 200) {
                            this.comtanyList()
                            this.$message({
                                message: '导入成功！',
                                type: 'success',
                            })
                        }
                    })
                    .catch(error => {
                        console.log(error)
                    })
            },

            // 所属分类 下拉数据
            classifyGet() {
                this.$instance
                    .get('/v1/follow_class/list', {
                        params: {
                            pageNum: this.currentPage - this.start,
                            pageSize: 100,
                        },
                    })
                    .then(res => {
                        if (res.data.status === 200) {
                            this.regions = res.data.data.elements
                        }
                    })
                    .catch(error => {
                        console.log('error: ', error)
                    })
            },
            // 人员列表
            comtanyList() {
                this.loading = true
                this.$instance
                    .get('/v1/follow/list', {
                        params: {
                            pageNum: this.currentPage - this.start,
                            pageSize: 10,
                            // 证件类型
                            cardType: this.nature,
                            // 所属分类
                            typeCode: this.region,
                            // 搜索关键字
                            searchKey: this.input,
                        },
                    })
                    .then(res => {
                        if (res.data.status === 200) {
                            this.loading = false
                            this.pagingData = res.data.data
                            this.pagingData.elements.map(val => {
                                this.natures.map(value => {
                                    if (value.number === val.cardType) {
                                        val.cardTypeC = value.text
                                    }
                                })
                            })
                        }
                    })
                    .catch(error => {
                        console.log('error: ', error)
                    })
            },
            // 点击 搜索
            searchClick() {
                // 当前页数
                this.currentPage = 1
                this.comtanyList()
            },
            // 导出数据
            exportClick() {
                this.exportLoad = true
                this.$instance
                    .get('/v1/follow/list/download', {
                        params: {
                            pageSize: 10000,
                            // 证件类型
                            cardType: this.nature,
                            // 所属分类
                            typeCode: this.region,
                            // 搜索关键字
                            searchKey: this.input,
                        },
                        responseType: 'arraybuffer',
                    })
                    .then(res => {
                        const blob = new Blob([res.data], {
                            type:
                                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                        })
                        // IE
                        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                            window.navigator.msSaveOrOpenBlob(blob, 'data')
                        } else {
                            this.imatateDownloadByA(
                                window.URL.createObjectURL(blob),
                                '本地关注人员data'
                            )
                        }
                        this.exportLoad = false
                    })
                    .catch(error => {
                        console.log('error: ', error)
                        this.exportLoad = false
                    })
            },
            //  @param {String} href
            //  @param {String} filename
            imatateDownloadByA(href, filename) {
                const a = document.createElement('a')
                a.download = filename
                a.style.display = 'none'
                a.href = href
                // console.log('a.href: ', a.href)
                document.body.appendChild(a)
                a.click()
                a.remove()
                window.URL.revokeObjectURL(href)
            },
            // 点击分页-当前页
            handleCurrentChange(val) {
                this.currentPage = val
                // 搜索并拉取列表
                this.comtanyList()
            },
            // 点击 添加人员
            addFun() {
                this.addCompanyShow = true
                this.titlePop = '添加本地关注人员'
            },
            // 点击 编辑
            editFun(row, index) {
                this.addCompanyShow = true
                this.titlePop = '编辑本地关注人员'
                this.deleteIndex = index
            },
            // 弹窗-编辑-点击取消
            cancelEquipmentFun() {
                this.addCompanyShow = false
            },
            // 弹窗-编辑-点击确定
            determineEquipmentFun() {
                // 是否显示-弹窗-编辑
                this.addCompanyShow = false
                // 搜索并拉取列表
                this.comtanyList()
            },
            // 点击 删除
            deleteFun(row, index) {
                this.deletePopShow = true
                this.deleteIndex = index
            },
            // 弹窗-删除-点击取消
            deletePopFun() {
                this.deletePopShow = false
            },
            // 弹窗-删除-点击确定
            determineDelFun() {
                var value = ''
                if (this.multipleSelection.length === 0) {
                    value = this.pagingData.elements[this.deleteIndex].id
                } else {
                    let idMy = []
                    this.multipleSelection.map(val => {
                        idMy.push(val.id)
                    })
                    value = idMy.join(',')
                }
                this.$instance
                    .post('/v1/follow/del?idStr=' + value, {})
                    .then(res => {
                        if (res.data.status === 200) {
                            this.comtanyList()
                            this.deletePopShow = false
                            this.$message({
                                message: '删除本地关注人员成功！',
                                type: 'success',
                            })
                        }
                    })
                    .catch(error => {
                        console.log(error)
                    })
            },
        },
    }
</script>
<style lang="scss" scoped>
    .personnel-admin {
        width: calc(100% - 40px);
        margin: 20px;
        background-color: #fff;
        // 搜索
        & ::v-deep .companySearch {
            width: 100%;
            height: 64px;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: space-between;
            padding: 0px 20px;
            box-sizing: border-box;
            border-bottom: 1px #f0f0f0 solid;
            .el-button--primary {
                line-height: 32px;
                padding: 0 12px;
                border: unset;
            }
            .titleButton {
                line-height: 30px;
                padding: 0 12px;
            }

            // 批量导入？ 内容
            .importCon {
                display: flex;
                flex-direction: column;
                align-items: center;
                .importBu {
                    color: #ff6600;
                    text-decoration: underline;
                    cursor: pointer;
                }
            }
            // 批量导入？ 按钮
            .dialog-footer {
                display: flex;
                justify-content: center;
                .titleButton {
                    width: 100px;
                    margin-left: 10px;
                }
            }
            .titleSearch {
                display: flex;
                flex-wrap: wrap;
                .titleSear {
                    position: relative;
                    .el-select {
                        .el-input__inner {
                            padding-right: 46px;
                        }
                        .el-input__suffix {
                            margin-right: 18px;
                            .el-icon-arrow-up {
                                display: none;
                            }
                        }
                    }
                    .iconSearch {
                        width: 24px;
                        position: absolute;
                        top: 9px;
                        right: 12px;
                        color: rgb(182, 182, 182);
                        font-size: 14px;
                        background-color: #fff;
                        text-align: center;
                        z-index: 2;
                        cursor: pointer;
                    }
                }
                .el-select > .el-input,
                .el-input {
                    margin-right: 6px;
                }
                .el-select > .el-input {
                    width: 150px;
                }
                .el-input {
                    width: 320px;
                    .el-input__inner {
                        height: 32px;
                        line-height: 32px;
                    }
                    .el-input__suffix {
                        i {
                            line-height: 32px;
                        }
                    }
                }
            }
        }
        .companyTableC {
            width: calc(100% - 40px);
            height: calc(100% - 144px);
            margin: 20px;
            // 表格
            & ::v-deep .companyTable {
                height: 100%;
                border: 1px #f0f0f0 solid;
                overflow: auto;
                .buttonC {
                    padding-right: 10px;
                }
                .el-table td,
                .el-table th {
                    height: 49px;
                    padding: 4px 0;
                }
            }
            // 翻页
            .el-pagination {
                text-align: right;
                margin-top: 10px;
                padding: unset;
            }
        }
    }
</style>
